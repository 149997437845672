<template>
  <TTableAdvance
    :items="list"
    :fields="fields"
    :store="store"
    @click-clear-filter="clearFilter"
    resource="/customer/orders"
    reloadable
    enterable
    @click-reload="fetch"
  >
    <template #id="{ item }">
      <td>
        <TMessage :content="item.id" noTranslate bold />
      </td>
    </template>
    <template #first_item="{ item }">
      <td>
        <SCardProductItems
          v-if="item.first_item"
          :item="item.first_item.product"
        />
        <TMessageNotFound v-else />
      </td>
    </template>
    <template #status="{ item }">
      <td>
        <SMessageOrderStatus :id="item.status_id" />
      </td>
    </template>
    <template #type="{ item }">
      <td class="text-nowrap">
        <TMessage :content="item.type.name" noTranslate bold color="muted" />
      </td>
    </template>
    <template #created_at="{ item }">
      <td>
        <TMessageDateTime :content="item.created_at" small />
      </td>
    </template>
    <template #id-filter>
      <TInputText :value.sync="filter.id" @update:value="filterChange" />
    </template>
    <template #customer_id-filter>
      <SSelectCustomer
        :value.sync="filter.customer_id"
        @change="filterChange"
        noCustomLabel
        prependAll
      />
    </template>
    <template #first_item-filter>
      <TInputText
        :value.sync="filter['items.product_id']"
        @update:value="filterChange"
        placeholder="Jancode"
      />
    </template>
    <template #status-filter>
      <SSelectOrderStatus
        store="order.statuses"
        :value.sync="filter.status_id"
        @change="filterChange"
      />
    </template>
    <template #created_at-filter>
      <TInputDateTimeRange
        :value.sync="dateRange"
        @update:value="setUpdatedAtFilter"
      />
    </template>
  </TTableAdvance>
</template>

<script>
export default {
  data() {
    return {
      store: "order.customer_orders",
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Order Id",
        },
        {
          key: "first_item",
          label: "Product",
          _style: "min-width: 350px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
        },
        {
          key: "type",
          label: "Type",
          _classes: "text-nowrap",
        },
        {
          key: "created_at",
          sorter: true,
          label: "Created at",
          _style: "width: 150px; max-width: 150px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    if (this.store) {
      this.$store.dispatch(`${this.store}.fetch.if-first-time`);
    }
  },
  computed: {
    list() {
      return this.$store.getters[`${this.store}.list`];
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(`${this.store}.apply-query`, filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
